import { gql, useApolloClient } from '@apollo/client';
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ACCOUNT_QUERY = gql`
  query GetAccount {
    account @client {
      userId
      email
      phone
      isAuthenticated
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    button: {
      margin: theme.spacing(1),
    },
    card: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    centered: {
      maxWidth: '640px',
      margin: 'auto',
    },
    action: {
      margin: theme.spacing(1),
    },
  }),
);

const Paywall = ({ children }: { children: any }) => {
  const classes = useStyles();

  const globalState = useApolloClient();
  const [account, setAccount] = useState({
    userId: 'guest@navability.io',
    name: 'Guest',
    email: 'guest@navability.io',
    isAuthenticated: true,
  });
  useEffect(() => {
    globalState.query({ query: ACCOUNT_QUERY }).then((result) => {
      setAccount(result.data.account);
    });
  }, []);

  if (account.isAuthenticated) {
    return <div> {children} </div>;
  }

  const login = () => {
    if (typeof window !== `undefined`) {
      navigate('/login', { state: { prevPath: window.location.pathname } });
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            This section requires an account.
          </Typography>
          <Typography gutterBottom variant="body1" component="p">
            Please login for access.
          </Typography>
        </CardContent>
        <CardActions>
          <div className={classes.centered}>
            <Button className={classes.action} variant="contained" color="secondary" onClick={login}>
              Login
            </Button>
          </div>
        </CardActions>
      </Card>
    </div>
  );
};

Paywall.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Paywall;
