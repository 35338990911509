import { Check, FileCopy } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grow, TextField } from '@mui/material';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SEO from '../../components/common/seo';
import Paywall from '../../components/paywall/paywall';
import { getIDToken } from '../../services/token-storage';

export default function ConnectPage() {
  const [copied, setCopied] = useState<boolean>(false);
  return (
    <Box>
      <SEO title="NavAbility Connect" />
      <Paywall>
        <Card>
          <CardHeader title="Authentication Token" subheader="It's dangerous out there, take this token! (Don't share it with anyone though!)" />
          <CardContent style={{ padding: '0px', margin: '20px' }}>
            <TextField variant="outlined" disabled={true} multiline={true} rows={10} value={getIDToken()} fullWidth/>
          </CardContent>
          <CardActions>
            <CopyToClipboard text={getIDToken()} onCopy={() => setCopied(true)}>
              <Button variant="contained" color="secondary" startIcon={<FileCopy />}>Copy to Clipboard</Button>
            </CopyToClipboard>
            {copied && (
              <Grow mountOnEnter in={true}>
                <Check color="action" />
              </Grow>
            )}
          </CardActions>
        </Card>
      </Paywall>
    </Box>
  );
}
